<template>
    <div class="gfs-bg">
        <img class="gfs-bg-img" 
            src="@/assets/images/get-free-samples/get-free-samples-desc.jpg" 
            alt="" />

        <div class="l-content get-free-samples">
            
            <van-form @submit="onSubmit">
                <van-field
                    required
                    v-model="businessName"
                    name="businessName"
                    label="Business Name"
                    placeholder="Business Name"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />
                <van-field
                    required
                    v-model="socialAccounts"
                    name="socialAccounts"
                    label="Website/Linkin/FB"
                    placeholder="Website/Linkin/FB"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />
                <van-field name="type" label="Type">
                    <template #input>
                        <van-radio-group v-model="type" direction="horizontal">
                            <van-radio name="1">Manufacturer</van-radio>
                            <van-radio name="2">Reseller</van-radio>
                            <!-- <van-radio name="3">Customer</van-radio> -->
                            <van-radio name="4">HealthCare</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field
                    required
                    v-model="contactPerson"
                    name="contactPerson"
                    label="Contact person"
                    placeholder="Contact person"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />
                <van-field
                    required
                    v-model="phone"
                    name="phone"
                    label="Phone"
                    placeholder="Phone"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />
                <van-field
                    required
                    readonly
                    clickable
                    name="area"
                    :value="regionVaule"
                    :label="enableSimpleTextCountryLabel"
                    :placeholder="enableSimpleTextCountryPlaceholder"
                    @click="getInitShowArea"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />
                <van-popup :style="{ height: '85%' }" v-model="showOneArea" position="bottom">
                    <div class="van-picker__toolbar">
                        <button 
                            type="button" 
                            class="van-picker__cancel"
                            @click="showOneArea = false">Cancel</button>
                        <button 
                            type="button" 
                            class="van-picker__confirm"
                            @click="onAreaOneMyConfirm">Confirm</button>
                    </div>
                    
                    
                    <van-popover
                        class="vanSearchCountryValue"
                        placement="bottom-start"
                        v-model="showPopover"
                        trigger="click"
                        :offset="offsetActions"
                        :actions="actions"
                        @select="onCountrySelect"
                        @>
                        <template #reference>
                            <van-search 
                                v-model="vanSearchCountryValue"
                                @input="onCountrySearch"
                                placeholder="Enter keywords to find country"
                            />
                        </template>
                    </van-popover>

                    <div class="country-tags">
                        <div class="tag" 
                            v-for="(item, cI) in commonCountriesDatas" :key="cI"
                            @click="onCountryManualSelection(item)">
                            {{item.text}}
                        </div>
                    </div>

                    <van-picker
                        visible-item-count="10"
                        ref="areaOnePicker"
                        confirm-button-text="Confirm"
                        cancel-button-text="Cancel"
                        @cancel="showOneArea = false"
                        :columns="areaListOneDatas"
                        :loading="areaListLoading"
                        @confirm="onAreaOneConfirm"
                    />

                    
                </van-popup>
                
                <van-popup v-model="showArea" position="bottom">
                    <van-picker 
                        confirm-button-text="Confirm"
                        cancel-button-text="Cancel"
                        ref="areaPicker"
                        show-toolbar
                        :loading="areaListLoading"
                        @cancel="showArea = false"
                        @confirm="onAreaConfirm"
                        :columns="areaListDatas" 
                        @change="onAreaListChange" />
                </van-popup>

                <van-field
                    required
                    v-model="state"
                    name="state"
                    label="State"
                    placeholder="State"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />

                <van-field
                    required
                    v-model="city"
                    name="city"
                    label="City"
                    placeholder="City"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />

                <van-field
                    required
                    v-model="streetAddress"
                    name="streetAddress"
                    label="Street Address"
                    placeholder="Street Address"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />
                <van-field
                    required
                    v-model="postCode"
                    name="postCode"
                    label="PostCode"
                    placeholder="PostCode"
                    :rules="[{ pattern: /[^ ]{1,}/, message: 'This is a required item' }]"
                />

                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">Submit</van-button>
                </div>
            </van-form>
            
        </div>
    </div>
</template>


<script>
    import '@/assets/less/get-free-samples.less';
    import Vue from 'vue';
    import { 
        Form, Field, Button, RadioGroup, 
        Radio, Picker, Popup, Toast, Dialog, Search, Popover,
        Tag
    } from 'vant';
    import axios from 'axios';

    Vue.use(Tag);
    Vue.use(Popover);
    Vue.use(Search);
    Vue.use(Dialog);
    Vue.use(Toast);
    Vue.use(Picker);
    Vue.use(Popup);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    Vue.use(Button);
    Vue.use(Field);
    Vue.use(Form);
    
	export default {
		name: 'GetFreeSamples',
		props: [
		],
		components: {
		},
		data() {
            
			return {
                businessName: '',
                socialAccounts: '', // 社交网络账号
                type: '1',
                contactPerson: '',
                phone: '',
                currentRegionInfo: {
                    countryId: 0,
                    stateId: 0,
                    cityId: 0
                },
                streetAddress: '',
                state: '',
                city: '',
                postCode: '',

                regionVaule: '',
                showArea: false,
                showOneArea: false,
                areaListLoading: true,
                areaList: [
                ],
                areaListDatas: [
                ],
                areaListOneDatas: [],

                isEnableSimpleCountry: true, // 是否地址筛选只使用国家


                showPopover: false,
                // 通过 actions 属性来定义菜单选项
                actions: [
                ],
                offsetActions: [20, 0],
                vanSearchCountryValue: '',

                
                enableSimpleTextCountryLabel: 'Region selection',
                enableSimpleTextCountryPlaceholder: 'Click to select a province or city',

                commonCountriesDatas: [
                    { text: 'United States', idKey: '5452' },
                    { text: 'Portugal', idKey: '6130' },
                    { text: 'Netherlands', idKey: '4722' },
                    { text: 'Italy', idKey: '7133' },

                    { text: 'Hungary', idKey: '7025' },
                    { text: 'Greece', idKey: '6981' },
                    { text: 'United Kingdom', idKey: '7263' },
                    { text: 'France', idKey: '4302' },

                    { text: 'Spain', idKey: '6929' },
                    { text: 'Denmark', idKey: '4096' },
                    { text: 'Germany', idKey: '4112' },
                    { text: 'Switzerland', idKey: '6236' },

                    { text: 'Belgium', idKey: '3888' },
                    { text: 'Austria', idKey: '3729' },

                    { text: 'Australia', idKey: '3687' },
                    { text: 'India', idKey: '7201' },
                    { text: 'Japan', idKey: '6164' },
                    { text: 'Korea', idKey: '4539' }
                ]
            }
		},
        methods: {
            onCountrySelect(action) {
                this.$refs.areaOnePicker.setIndexes([
                    action.selectCountryIndex
                ]);
            },
            getStringCommon(str) {
                if (typeof str != 'string') {
                    return '';
                }
                return str.replace(/[^a-zA-Z0-9.\-_]{1,}/gi, '').toLowerCase();
            },
            onCountryManualSelection(item) {
                const that = this;
                if (typeof that.areaListOneDatas == 'object' && null != that.areaListOneDatas) {
                    for (const key in that.areaListOneDatas) {
                        if (!Object.hasOwnProperty.call(that.areaListOneDatas, key)) {
                            continue;
                        }
                        const element = that.areaListOneDatas[key];
                        if (element.idKey == item.idKey) {
                            that.$refs.areaOnePicker.setIndexes([
                                key
                            ]);
                            break;
                        }
                    }
                }
            },
            onCountrySearch(value) {
                const that = this;
                value = this.getStringCommon(value.trim());
                
                let actionsLists = [];
                if (typeof that.areaListOneDatas == 'object' && null != that.areaListOneDatas) {
                    for (const key in that.areaListOneDatas) {
                        if (!Object.hasOwnProperty.call(that.areaListOneDatas, key)) {
                            continue;
                        }
                        const element = that.areaListOneDatas[key];
                        if (this.getStringCommon(element.text).indexOf(value) === 0) {
                            actionsLists.push({ 
                                selectCountryIndex: key,
                                idKey: element.idKey,
                                text: element.text, 
                                className: 'vanSearchCountryValueList' 
                            });
                        }
                        if (actionsLists.length >= 6) {
                            break;
                        }
                    }
                }
                that.actions = actionsLists;
                that.showPopover = true;
            },
            onSubmit(values) {
                const that = this;
                Toast.loading({
                    message: 'Information processing...',
                    forbidClick: true,
                    duration: 0
                });
                for (const key in this.currentRegionInfo) {
                    if (!Object.hasOwnProperty.call(this.currentRegionInfo, key)) {
                        continue;
                    }
                    values[key] = this.currentRegionInfo[key];
                }
                values.isEnableSimpleCountry = that.isEnableSimpleCountry;
                
                axios({
                    url: '/mobileApi',
                    method: "post",
                    crossdomain: true,
                    params: Object.assign({}, values, {
                        cpAction: 'addGetFreeSamples'
                    })
                }).then(res => {
                    Toast.clear();
                    if (typeof res == 'object' && null != res &&
                        typeof res.data == 'object' && null != res.data && 
                        res.data.code != 1) {
                        Toast(res.data.msg);
                        return;
                    }
                    Dialog.alert({
                        title: 'Your Free Sample is Being Processed',
                        message:  "Any questions please contact us: <br>" +
                                    "+1 831 228 8614 🇺🇸" + "<br>" +
                                    "+1 714-476-0102 🇺🇸" + "<br>" + 
                                    "3 670 4097888 🇪🇺",
                        confirmButtonText: 'Confirm'
                    }).then(() => {
                        that.businessName = '';
                        that.socialAccounts = '';
                        that.contactPerson = '';
                        that.phone = '';
                        that.streetAddress = '';
                        that.postCode = '';
                    });
                    console.log('submit res', res.data);
                }).catch(() => {
                    Toast.clear();
                });
            },

            onAreaOneMyConfirm() {
                const that = this;
                that.$refs.areaOnePicker.confirm();
            },
            /**
             * 国家搜索框确认选择
             */
            onAreaOneConfirm(values) {
                this.regionVaule = values.text;
                this.currentRegionInfo = {
                    countryId: values.idKey,
                    stateId: 0,
                    cityId: 0
                };
                this.showOneArea = false;
            },
            onAreaConfirm(values, indexes) {
                this.regionVaule = values
                    .filter((item) => !!item)
                    .map((item) => item)
                    .join('/');
                
                this.currentRegionInfo = {
                    countryId: this.areaList[(indexes[0])]['idKey'],
                    stateId: this.areaList[(indexes[0])]['children'][(indexes[1])]['idKey'],
                    cityId: this.areaList[(indexes[0])]['children'][(indexes[1])]['children'][(indexes[2])]['idKey']
                };
                this.showArea = false;
            },
            onAreaListChange(picker, values, index) {
                const that = this;
                const allIndexes = picker.getIndexes();
                const allValues = picker.getValues();
                if (index == 0) {
                    // 点击的时候国家
                    that.areaListLoading = true;
                    that.openShowArea(
                        1, allValues[0]['idKey'], allIndexes[0], 0, {
                            success: (allIndexes) => {
                                that.areaListDatas = that.areaList;
                                picker.setIndexes(allIndexes);
                                that.areaListLoading = false;
                            }
                        }
                    );
                } else if (index == 1) {
                    that.areaListLoading = true;
                    that.openShowArea(
                        2, allValues[1]['idKey'], 
                        allIndexes[0], allIndexes[1], {
                            success: (allIndexes) => {
                                that.areaListDatas = that.areaList;
                                picker.setIndexes(allIndexes);
                                that.areaListLoading = false;
                            }
                        }
                    );
                }
            },

            getInitShowArea() {
                const that = this;

                /**
                 * 是否仅仅启用国家搜索
                 */
                if (true == that.isEnableSimpleCountry) {
                    that.showOneArea = true;
                    that.getAllAreaList(0).then(datas => {
                        let dataView = [];
                        for (const key in datas) {
                            if (!Object.hasOwnProperty.call(datas, key)) {
                                continue;
                            }
                            const element = datas[key];
                            dataView.push({
                                text: element.name_en,
                                idKey: element.id
                            });
                        }
                        
                        that.areaListOneDatas = dataView;
                        that.areaListLoading = false;
                    });
                    return;
                }
                


                that.openShowArea(
                    0, 0, 
                    0, 0, {
                        success: (allIndexes) => {
                            that.areaListDatas = that.areaList;
                            that.$refs.areaPicker.setIndexes(allIndexes);
                            that.areaListLoading = false;
                        }
                    }
                );
            },

            /**
             * 得到所有地区的地址
             */
            getAllAreaList(
                currentParentId
            ) {
                const that = this;
                return new Promise((r, e) => {
                    if (typeof that._cacheAreaLists != 'object' || null == that._cacheAreaLists) {
                        that._cacheAreaLists = {};
                    }
                    if (typeof that._cacheAreaLists[''+currentParentId] == 'object' && 
                        null != that._cacheAreaLists[''+currentParentId]) {
                            return r(that._cacheAreaLists[''+currentParentId]);
                    }
                    axios({
                        url: '/mobileApi',
                        method: "post",
                        crossdomain: true,
                        params: {
                            cpAction: 'getAreaLists',
                            parentId: currentParentId
                        }
                    }).then(res => {
                        const datas = res.data;
                        if (typeof datas != 'object' || null == datas || 
                            typeof datas.list != 'object' || null == datas.list ||
                            datas.list.lenght <= 0) {
                            return e('error');
                        }
                        that._cacheAreaLists[''+currentParentId] = datas.list;
                        return r(datas.list);
                    });
                });
            },
            
            openShowArea(
                level, parentId, 
                currentCountryIndex = 0, 
                currentStateIndex = 0,
                options = {}
            ) {
                this.showArea = true;
                const that = this;
                that.getAllAreaList(parentId).then(datas => {
                    let dataView = [];
                    for (const key in datas) {
                        if (!Object.hasOwnProperty.call(datas, key)) {
                            continue;
                        }
                        const element = datas[key];
                        dataView.push({
                            text: element.name_en,
                            idKey: element.id
                        });
                    }
                    if (level == 0) {
                        that.areaList = dataView;
                        return that.openShowArea(
                            1, that.areaList[currentCountryIndex]['idKey'], 
                            currentCountryIndex, currentStateIndex, options
                        );
                    }
                    if (level == 1) {
                        // that.$set(that.areaList[currentCountryIndex], 'children', dataView);
                        that.areaList[currentCountryIndex]['children'] = dataView;
                        return that.openShowArea(
                            2,
                            that.areaList[currentCountryIndex]['children'][currentStateIndex]['idKey'], 
                            currentCountryIndex, currentStateIndex, options
                        );
                    }

                    if (level == 2) {
                        // that.$set(
                        //     that.areaList[currentCountryIndex]['children'][currentStateIndex], 
                        //     'children', dataView
                        // );
                        that.areaList[currentCountryIndex]['children'][currentStateIndex]['children'] = dataView;
                        
                        if (typeof options == 'object' && null != options &&
                            typeof options.success == 'function') {
                                options.success([
                                    currentCountryIndex,
                                    currentStateIndex,
                                    0
                                ]);
                        }
                    }
                });
            },

            setEnableSimpleTextCountry() {
                this.enableSimpleTextCountryLabel = 'Region selection';
                this.enableSimpleTextCountryPlaceholder = 'Click to select a province or city';
                if (true == this.isEnableSimpleCountry) {
                    this.enableSimpleTextCountryLabel = 'Country';
                    this.enableSimpleTextCountryPlaceholder = 'Click to select Country';
                }
            }
        },
        created() {
            /**
             * 是否显示头部
             */
            this.$parent.isShowHeader = true;
            this.$parent.setHeaderMarginTop();


            this.setEnableSimpleTextCountry();
        },
        mounted() {
        }
	}
</script>